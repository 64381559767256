import { COLORS } from '@landing/constants';
import { FC, memo } from 'react';

interface Props {
  color?: string;
}

const SliderNextArrow: FC<Props> = ({ color = COLORS.mint }) => {
  return (
    <svg
      width="10"
      height="15"
      viewBox="0 0 10 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.299805 13.418C0.299805 13.6388 0.402776 13.8628 0.603656 14.0303C1.0057 14.3655 1.67051 14.3655 2.07255 14.0303L9.59595 7.75684C9.79008 7.59497 9.8998 7.36647 9.8998 7.14453C9.8998 6.92471 9.80387 6.70559 9.59595 6.53222L2.0824 0.267444C1.68626 -0.08352 1.00569 -0.0879765 0.603655 0.247268C0.201615 0.582513 0.201615 1.13688 0.603655 1.47212L0.603936 1.47235L7.40626 7.13351L0.603655 12.8059C0.402495 12.9737 0.299805 13.1975 0.299805 13.418Z"
        fill={color}
      />
    </svg>
  );
};

export default memo(SliderNextArrow);
