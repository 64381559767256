import { FC, useEffect, useState } from 'react';
import { ArrowIcon } from '@landing/assets/svgComponents/arrow';
import { useRouter } from 'next/router';
import styled, { css } from 'styled-components';
import apx from '@landing/constants/adaptivepx';
import { COLORS } from '@landing/constants';

interface IPaginationProps {
  total: number;
  limit: number;
}

const Pagination: FC<IPaginationProps> = ({ total, limit }) => {
  const router = useRouter();
  const { page = 1 } = router.query;

  const itemsCount = limit || 10;

  const currentPage = +page;

  const setCurrentPage = (pageNumber: number) => {
    if (pageNumber !== 1) {
      router.query.page = pageNumber.toString();
    } else {
      const queryParams = {
        ...router.query,
      };

      delete queryParams.page;
      router.query = queryParams;
    }

    router.push(router);
  };

  const [pageItem, SetPageItem] = useState({
    start: 0,
    end: itemsCount,
  });

  const onPageChangeEvent = (start: number, end: number) => {
    SetPageItem({
      start: start,
      end: end,
    });
  };

  const numOfPages = Math.ceil(total / itemsCount);

  const numOfButtons: number[] = [];

  for (let i = 1; i <= numOfPages; i++) {
    numOfButtons.push(i);
  }

  const prevPageClick = () => {
    if (currentPage === 1) {
      setCurrentPage(currentPage);
    } else {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPageClick = () => {
    if (currentPage === numOfButtons.length) {
      setCurrentPage(currentPage);
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  const [arrOfCurrButtons, setArrOfCurrButtons] = useState<
    Array<number | string>
  >([]);

  useEffect(() => {
    let tempNumberOfButtons: Array<string | number> = [...arrOfCurrButtons];

    const dotsInitial = '...';
    const dotsLeft = '... ';
    const dotsRight = ' ...';

    if (numOfButtons.length < 6) {
      tempNumberOfButtons = numOfButtons;
    } else if (currentPage >= 1 && currentPage <= 3) {
      tempNumberOfButtons = [1, 2, 3, 4, 5, dotsInitial, numOfButtons.length];
    } else if (currentPage === 4) {
      const sliced = numOfButtons.slice(0, 5);
      tempNumberOfButtons = [...sliced, dotsInitial, numOfButtons.length];
    } else if (currentPage > 4 && currentPage < numOfButtons.length - 2) {
      // from 5 to 8 -> (10 - 2)
      const sliced1 = numOfButtons.slice(currentPage - 2, currentPage);
      // sliced1 (5-2, 5) -> [4,5]
      const sliced2 = numOfButtons.slice(currentPage, currentPage + 1);
      // sliced1 (5, 5+1) -> [6]
      tempNumberOfButtons = [
        1,
        dotsLeft,
        ...sliced1,
        ...sliced2,
        dotsRight,
        numOfButtons.length,
      ];
      // [1, '...', 4, 5, 6, '...', 10]
    } else if (currentPage > numOfButtons.length - 3) {
      // > 7
      const sliced = numOfButtons.slice(numOfButtons.length - 4);
      // slice(10-4)
      tempNumberOfButtons = [1, dotsLeft, ...sliced];
    } else if (currentPage.toString() === dotsInitial) {
      // [1, 2, 3, 4, "...", 10].length = 6 - 3  = 3
      // arrOfCurrButtons[3] = 4 + 1 = 5
      // or
      // [1, 2, 3, 4, 5, "...", 10].length = 7 - 3 = 4
      // [1, 2, 3, 4, 5, "...", 10][4] = 5 + 1 = 6
      setCurrentPage(+arrOfCurrButtons[arrOfCurrButtons.length - 3] + 1);
    } else if (currentPage.toString() === dotsRight) {
      setCurrentPage(+arrOfCurrButtons[3] + 2);
    } else if (currentPage.toString() === dotsLeft) {
      setCurrentPage(+arrOfCurrButtons[3] - 2);
    }

    setArrOfCurrButtons(tempNumberOfButtons);
    const value = currentPage * itemsCount;

    onPageChangeEvent(value - itemsCount, value);
  }, [currentPage, itemsCount, numOfPages]);

  return (
    <StyledPagination>
      <ul>
        {currentPage > 1 && (
          <PrevPageItem>
            <button onClick={prevPageClick}>
              <ArrowIcon />
            </button>
          </PrevPageItem>
        )}

        {arrOfCurrButtons.map((data, index) => {
          return (
            <Item key={index} isActive={data === currentPage}>
              <button onClick={() => setCurrentPage(+data)}>
                <span>{data}</span>
              </button>
            </Item>
          );
        })}

        {currentPage < numOfButtons.length && (
          <NextPageItem>
            <button onClick={nextPageClick}>
              <ArrowIcon />
            </button>
          </NextPageItem>
        )}
      </ul>
    </StyledPagination>
  );
};

const NextPageItem = styled.li``;

const PrevPageItem = styled.li`
  svg {
    transform: rotate(180deg);
  }
`;

const Item = styled.li<{ isActive: boolean }>`
  ${({ isActive }) => {
    if (!isActive) return;

    return css`
      background: ${COLORS.green} !important;

      button {
        color: ${COLORS.white} !important;
      }
    `;
  }}
`;

const StyledPagination = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: ${apx.d(51)};
  margin-bottom: ${apx.d(91)};

  ul {
    display: flex;
    gap: ${apx.d(4)};
  }

  ul li {
    width: ${apx.d(34)};
    height: ${apx.d(34)};

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    background: #f7f7f7;
    border-radius: ${apx.d(5)};

    button {
      font-weight: 700;
      font-size: ${apx.d(20)};
      line-height: 150%;
      color: ${COLORS.green};
      width: 100%;
      height: 100%;

      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: ${apx.d(2)};
    }
  }

  img path {
    stroke: ${COLORS.green};
  }

  ${NextPageItem}, ${PrevPageItem} {
    background: none !important;

    svg {
      width: ${apx.d(34)};
      height: ${apx.d(34)};
    }
  }

  @media (max-width: 485px) {
    margin-top: ${apx.l(51)};
    margin-bottom: ${apx.l(51)};

    ul {
      gap: ${apx.l(14)};
    }

    ul li {
      width: ${apx.l(54)};
      height: ${apx.l(54)};
      border-radius: ${apx.l(5)};

      button {
        font-size: ${apx.l(20)};
        padding-top: ${apx.l(2)};
      }
    }

    ${NextPageItem}, ${PrevPageItem} {
      svg {
        width: ${apx.l(34)};
        height: ${apx.l(34)};
      }
    }
  }

  @media screen and (max-width: 375px) {
    margin-top: ${apx.m(32)};

    ul {
      gap: ${apx.m(4)};
    }

    ul li {
      width: ${apx.m(24)};
      height: ${apx.m(24)};

      border-radius: ${apx.m(5)};

      button {
        font-size: ${apx.m(14)};
        padding-top: ${apx.m(2)};
      }
    }

    ${NextPageItem}, ${PrevPageItem} {
      svg {
        width: ${apx.m(24)};
        height: ${apx.m(24)};
      }
    }
  }
`;

export default Pagination;
