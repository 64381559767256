import { COLORS } from '@landing/constants';
import apx from '@landing/constants/adaptivepx';
import { BreakpointsEnum } from '@landing/types';
import { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';

interface InfoParamsProps {
  className?: string;
}

const InfoParam: FC<PropsWithChildren<InfoParamsProps>> = ({
  children,
  className,
}) => {
  return <StyledInfoParam className={className}>{children}</StyledInfoParam>;
};

export default InfoParam;

const StyledInfoParam = styled.div`
  display: flex;
  align-items: center;
  gap: ${apx.d(8)};

  span {
    font-weight: 500;
    font-size: ${apx.d(17)};
    line-height: 120%;
    color: ${COLORS.dark700};
    opacity: 0.2;
    margin-bottom: -${apx.d(6)};
  }

  svg {
    width: ${apx.d(20)} !important;
    height: ${apx.d(20)} !important;
    margin-top: ${apx.d(3)} !important;
    opacity: 0.2;
  }

  @media screen and (max-width: ${BreakpointsEnum.mb}px) {
    gap: ${apx.m(8)};

    span {
      font-size: ${apx.m(14)};
      margin-bottom: -${apx.m(4)};
    }

    svg {
      width: ${apx.m(20)} !important;
      height: ${apx.m(20)} !important;
    }
  }
`;
