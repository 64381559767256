import { FC, ReactNode } from 'react';
import { useClientSize } from '@landing/hooks';
import styled, { css } from 'styled-components';
import { COLORS, TYPOGRAPHY } from '@landing/constants';
import Add from '@landing/ui/iconComponents/Add';
import ReactModal from 'react-modal';
import { BreakpointsEnum } from '@landing/types';

export interface Props extends ReactModal.Props {
  children?: ReactNode | ReactNode[];
  onClose: () => void;
  hasCloseBtn?: boolean;
}

export interface ModalProps extends Props {
  hasBackdropOnMobile?: boolean;
  isOpen: boolean;
  heading?: string;
  withoutHeader?: boolean;
}

const Modal: FC<ModalProps> = ({
  heading,
  withoutHeader = false,
  isOpen,
  hasBackdropOnMobile = true,
  children,
  hasCloseBtn = true,
  shouldCloseOnOverlayClick,
  onClose,
  ...props
}) => {
  const { getIsBreakpoint } = useClientSize();
  const isMobile = getIsBreakpoint('sm');

  const isBackdropOnMobile = hasBackdropOnMobile && isMobile;

  return (
    <ReactModal
      isOpen={isOpen}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      onRequestClose={onClose}
      overlayClassName="_"
      contentElement={(contentProps) => (
        <Content {...contentProps} $isBackdropOnMobile={isBackdropOnMobile}>
          {hasCloseBtn && (
            <CloseBtn onClick={onClose}>
              <Add />
            </CloseBtn>
          )}
          {!withoutHeader && (
            <Header>
              <Heading>{heading}</Heading>
            </Header>
          )}
          {children}
        </Content>
      )}
      overlayElement={(overlayProps, contentElement) => (
        <Overlay
          $isBackdropOnMobile={isBackdropOnMobile}
          $shouldCloseOnOverlayClick={!!shouldCloseOnOverlayClick}
          {...overlayProps}
        >
          {contentElement}
        </Overlay>
      )}
      {...props}
    />
  );
};

export default Modal;

interface OverlayStyleProps {
  $shouldCloseOnOverlayClick: boolean;
  $isBackdropOnMobile: boolean;
}

const CloseBtn = styled.button`
  display: flex;
  position: absolute;
  top: 44px;
  right: 32px;
  z-index: 1000;
  & > svg {
    transform: rotate(45deg);
  }

  @media (max-width: ${BreakpointsEnum.sm}px) {
    top: 24px;
    right: 16px;
  }
`;

const Content = styled.div<{ $isBackdropOnMobile: boolean }>`
  position: relative;
  background-color: ${COLORS.white};
  border-radius: 24px !important;
  outline: none;
  padding: 0 32px 40px 32px !important;
  max-width: 644px;
  width: 100%;
  height: fit-content;
  margin: auto;
  cursor: default;

  @media (max-width: ${BreakpointsEnum.sm}px) {
    max-width: 100%;
    width: 100%;
    ${({ $isBackdropOnMobile }) =>
      $isBackdropOnMobile &&
      css`
        // position: fixed;
        // bottom: 0;
      `};

    // border-radius: ${({ $isBackdropOnMobile }) =>
      $isBackdropOnMobile ? '24px 24px 0 0 !important' : '24px'};
    border-radius: 24px;
    padding: 0 16px 24px 16px !important;
    height: min-content;
    max-height: 90vh;
    overflow: auto;
  }
`;

const Overlay = styled.div<OverlayStyleProps>`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  background-color: ${COLORS.black40};
  overscroll-behavior: contain;
  cursor: ${({ $shouldCloseOnOverlayClick }) =>
    $shouldCloseOnOverlayClick && 'pointer'};
  z-index: 1000;
  overflow: auto;

  @media (min-width: ${BreakpointsEnum.sm}px) {
    padding: 40px 20px;
  }

  @media (max-width: ${BreakpointsEnum.sm}px) {
    padding: ${({ $isBackdropOnMobile }) =>
      $isBackdropOnMobile ? '0' : '0 16px'};
  }
`;

const Header = styled.div`
  @media (min-width: ${BreakpointsEnum.sm}px) {
    padding: 40px 56px 0 0;
  }

  @media (max-width: ${BreakpointsEnum.sm}px) {
    padding: 32px 56px 0 0;
  }
`;

const Heading = styled.div`
  color: ${COLORS.grayscale700};

  @media (min-width: ${BreakpointsEnum.sm}px) {
    ${TYPOGRAPHY.headline3Bold24};
  }

  @media (max-width: ${BreakpointsEnum.sm}px) {
    ${TYPOGRAPHY.heading5Semibold18};
    font-size: 18px;
    line-height: 1.3;
  }
`;
