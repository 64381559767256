import { useCallback, useState } from 'react';

type useToggleReturn = {
  state: boolean;
  setTrue: () => void;
  setFalse: () => void;
  toggle: () => void;
};

export function useToggle(initial = false): useToggleReturn {
  const [state, setState] = useState(initial);

  const setTrue = useCallback(() => setState(true), []);
  const setFalse = useCallback(() => setState(false), []);
  const toggle = useCallback(() => {
    setState((prevState) => !prevState);
  }, []);

  return { state, setTrue, setFalse, toggle };
}
