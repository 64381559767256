import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import RatingStar from '@landing/ui/iconComponents/RatingStar';
import { BreakpointsEnum } from '@landing/types';
import apx from '@landing/constants/adaptivepx';

interface RatingProps {
  rating: number;
}

const Rating: FC<RatingProps> = ({ rating }) => {
  const [ratingArray, setRatingArray] = useState<JSX.Element[]>(
    new Array(5).fill(<></>),
  );

  const constructRating = (currentRating: number) => {
    const updateArray = ratingArray.map((r: JSX.Element, i: number) => (
      <RatingStar key={i} color={i < currentRating ? '#FFB800' : ''} />
    ));

    setRatingArray(updateArray);
  };

  useEffect(() => {
    constructRating(rating);
  }, [rating]);

  return (
    <RatingWrapper>
      {ratingArray.map((r, i) => (
        <span key={i}>{r}</span>
      ))}
    </RatingWrapper>
  );
};

export default Rating;

const RatingWrapper = styled.div`
  display: flex;
  column-gap: ${apx.d(6)};
  margin-bottom: ${apx.d(12)};

  svg {
    width: ${apx.d(27)};
    height: ${apx.d(27)};
  }

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    column-gap: ${apx.l(4)};
    margin-bottom: ${apx.l(9)};

    svg {
      width: ${apx.l(20)};
      height: ${apx.l(20)};
    }
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    column-gap: ${apx.m(6)};
    margin-bottom: ${apx.m(12)};

    svg {
      width: ${apx.m(27)};
      height: ${apx.m(27)};
    }
  }
`;
