import { COLORS } from '@landing/constants';
import SliderNextArrow from '@landing/ui/iconComponents/SliderNextArrow';
import { FC, useRef } from 'react';
import styled from 'styled-components';

interface CustomNavigationProps {
  setPrevEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  setNextEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  isFirst: boolean;
  isEnd: boolean;
}

const CustomNavigation: FC<CustomNavigationProps> = ({
  setPrevEl,
  setNextEl,
  isFirst,
  isEnd,
}) => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  setPrevEl(() => prevRef.current);
  setNextEl(() => nextRef.current);

  return (
    <>
      <CustomPrev
        ref={prevRef}
        setPrevEl={setPrevEl}
        setNextEl={setNextEl}
        disabled={isFirst}
        isFirst={isFirst}
        isEnd={isEnd}
      >
        <SliderNextArrow />
      </CustomPrev>
      <CustomNext
        ref={nextRef}
        disabled={isEnd}
        setPrevEl={setPrevEl}
        setNextEl={setNextEl}
        isFirst={isFirst}
        isEnd={isEnd}
      >
        <SliderNextArrow />
      </CustomNext>
    </>
  );
};

export default CustomNavigation;

const CustomPrev = styled.button<CustomNavigationProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  cursor: pointer;
  background: ${COLORS.white};

  &:disabled {
    opacity: 0.5;
    cursor: auto;
  }

  svg {
    transform: rotate(180deg);
  }
`;

const CustomNext = styled.button<CustomNavigationProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  cursor: pointer;
  background: ${COLORS.white};

  &:disabled {
    opacity: 0.5;
    cursor: auto;
  }
`;
