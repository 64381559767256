import { COLORS } from '@landing/constants';
import apx from '@landing/constants/adaptivepx';
import { BreakpointsEnum } from '@landing/types';
import Link from 'next/link';
import { FC } from 'react';
import styled from 'styled-components';

interface BreadCrumbsProps {
  list: { name: string; href: string }[];
}

const BreadCrumbs: FC<BreadCrumbsProps> = ({ list }) => {
  return (
    <List>
      {list.map((item, index) => (
        <Item key={index}>
          <Link href={item.href}>{item.name}</Link>{' '}
          {index < list.length - 1 ? '/' : ''}
        </Item>
      ))}
    </List>
  );
};

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;

  li:not(:last-child) {
    margin-right: ${apx.d(5)} !important;
  }

  li:last-child a {
    color: ${COLORS.green};
  }

  @media screen and (max-width: ${BreakpointsEnum.mb}px) {
    li:not(:last-child) {
      margin-right: ${apx.m(5)} !important;
    }
  }
`;

const Item = styled.li`
  font-size: ${apx.d(18)};
  font-weight: 500;
  line-height: 120%;
  color: rgba(23, 46, 44, 0.6);
  white-space: nowrap;

  @media screen and (max-width: ${BreakpointsEnum.tablet}px) {
    font-size: ${apx.l(14)};
  }

  @media screen and (max-width: ${BreakpointsEnum.mb}px) {
    font-size: ${apx.m(14)};
  }
`;

export default BreadCrumbs;
