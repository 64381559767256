import { COLORS, TYPOGRAPHY } from '@landing/constants';
import {
  forwardRef,
  ForwardRefRenderFunction,
  InputHTMLAttributes,
  ReactNode,
  useId,
} from 'react';
import styled, { css } from 'styled-components';
import check from '@landing/assets/svg/check.svg';
import { BreakpointsEnum } from '@landing/types';
import apx from '@landing/constants/adaptivepx';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  text?: ReactNode;
  isFocused?: boolean;
  className?: string;
  error?: string;
}

const Checkbox: ForwardRefRenderFunction<HTMLInputElement, Props> = (
  { text, isFocused = false, className, error, checked, ...props },
  ref,
) => {
  const id = useId();
  const ownId = props.id ?? id;
  const hasError = !!error;

  return (
    <>
      <Input type="checkbox" {...props} ref={ref} id={ownId} checked={checked} />
      <Label
        className={className}
        htmlFor={id}
        $isFocused={isFocused}
        $hasError={hasError}
      >
        {text && <TextWrapper>{text}</TextWrapper>}
      </Label>
    </>
  );
};

export default forwardRef(Checkbox);

interface StyleProps {
  $isFocused: boolean;
  $hasError: boolean;
}

const TextWrapper = styled.div`
  top: -${apx.d(8)};
  margin-top: ${apx.d(8)};
  margin-left: ${apx.d(10)};

  @media screen and (max-width: ${BreakpointsEnum.mb}px) {
    font-size: ${apx.m(14)};
    line-height: ${apx.m(14)};
    margin-left: ${apx.m(8)};
  }
`;

const Label = styled.label<StyleProps>`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  width: 100%;

  ${TYPOGRAPHY.bodyRegular14};
  color: ${COLORS.grayscale700};

  &:before {
    content: '';
    display: inline-block;
    margin-top: 1px;
    min-width: 20px;
    min-height: 20px;
    border: 1px solid ${COLORS.grayscale200};
    border-radius: 4px;
  }

  ${({ $isFocused }) =>
    $isFocused &&
    css`
      &:before {
        border: 1px solid ${COLORS.primary300};
      }
    `};

  ${({ $hasError }) =>
    $hasError
      ? css`
          &:before {
            border: 1px solid ${COLORS.systemError};
          }
        `
      : css`
          &:hover {
            &:before {
              border: 1px solid ${COLORS.primary300};
            }
          }

          &:active {
            &:before {
              border: 1px solid ${COLORS.primary400};
              background-color: ${COLORS.grayscale100};
            }
          }
        `};
`;

const Input = styled.input`
  position: absolute;
  left: 0;
  opacity: 0;

  &:checked + ${Label} {
    &:before {
      background: url(${check.src}) center / cover no-repeat;
      border: 1px solid ${COLORS.primary500};
    }
  }

  &:not(:checked) + ${Label} svg {
    display: none;
  }
`;
