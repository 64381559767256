import React, { forwardRef, useId } from 'react';
import styled, { css } from 'styled-components';
import { COLORS, TYPOGRAPHY } from '@landing/constants';
import { BreakpointsEnum, InputState } from '@landing/types';
import CloseCircle from '@landing/ui/iconComponents/CloseCircle';
import TickCircle from '@landing/ui/iconComponents/TickCircle';
import ReactInputMask, { Props as ReactInputMaskProps } from 'react-input-mask';
import apx from '@landing/constants/adaptivepx';

export interface Props extends Omit<ReactInputMaskProps, 'inputRef' | 'mask'> {
  label?: string;
  textError?: string;
  inputState?: InputState | false;
  iconButton?: JSX.Element | false;
  maxLength?: number;
  isError?: boolean;
  className?: string;
  mask?: string | Array<string | RegExp>;
  clickOnIconButton?: () => void;
}

const TextInput = forwardRef<HTMLInputElement | ReactInputMask, Props>(
  (
    {
      label,
      textError,
      inputState = false,
      iconButton = false,
      maxLength,
      type = 'text',
      isError = false,
      className,
      mask,
      maskChar,
      alwaysShowMask,
      readOnly,
      clickOnIconButton,
      ...rest
    },
    ref,
  ) => {
    const ownId = useId();
    const id = rest.id || ownId;

    const getInputMaskProps = () => ({
      mask,
      alwaysShowMask,
      as: ReactInputMask,
      maskChar,
      inputRef: ref,
    });

    return (
      <Root className={className}>
        {label && <Label htmlFor={id}>{label}</Label>}
        <InputWrap>
          <Input
            id={id}
            type={type}
            $isPaddingBig={!!iconButton || !!inputState}
            maxLength={maxLength}
            $isError={isError}
            readOnly={readOnly}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ref={ref}
            {...(!readOnly && mask && getInputMaskProps())}
            {...rest}
          />
          <StateWrapper>
            {inputState === InputState.Success && (
              <TickCircle color={COLORS.systemSuccess} />
            )}
            {inputState === InputState.Error && (
              <CloseCircle color={COLORS.systemError} />
            )}
          </StateWrapper>
          {iconButton && (
            <Button type="button" onClick={clickOnIconButton}>
              {iconButton}
            </Button>
          )}
        </InputWrap>
        {textError && <TextError role="alert">{textError}</TextError>}
      </Root>
    );
  },
);

TextInput.displayName = 'TextInput';

const Root = styled.div`
  position: relative;
`;

const StateWrapper = styled.div`
  display: flex;
  position: absolute;
  right: 16px;
  svg {
    height: 16px;
    width: 16px;
  }
`;

const Label = styled.label`
  margin-bottom: 4px;
  ${TYPOGRAPHY.bodyMedium14}
  color: ${COLORS.grayscale600};
  display: block;

  @media (max-width: ${BreakpointsEnum.sm}px) {
    font-size: 14px;
    line-height: 1.2;
  }
`;

const InputWrap = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
`;

const Input = styled.input<{
  $isPaddingBig: boolean;
  $isError: boolean;
}>`
  background-color: ${COLORS.white};
  width: 100%;
  padding: ${({ $isPaddingBig }) =>
    $isPaddingBig ? '11px 48px 11px 16px' : '11px 16px'};
  ${TYPOGRAPHY.bodyRegular14}
  color: ${COLORS.grayscale700};
  border: 1px solid ${COLORS.grayscale100};
  border-radius: 10px;
  outline: none;

  &::placeholder {
    color: ${COLORS.grayscale400};
  }

  &:focus {
    &::placeholder {
      opacity: 0;
    }
  }

  ${({ $isError }) =>
    !$isError &&
    css`
      &:focus {
        border-color: ${COLORS.primary300};
      }
    `}

  ${({ $isError }) =>
    $isError &&
    css`
      border-color: ${COLORS.systemError};
    `}

  &:read-only {
    border-color: ${COLORS.grayscale100};
    cursor: default;
  }

  &:read-only:focus {
    box-shadow: none;
  }

  @media (max-width: ${BreakpointsEnum.sm}px) {
    font-size: 14px;
    line-height: 1.2;
  }
`;

export const TextError = styled.p`
  ${TYPOGRAPHY.footnoteMedium13}
  color: ${COLORS.systemError};
  margin-top: ${apx.d(4)};

  @media (max-width: ${BreakpointsEnum.sm}px) {
    font-size: 14px;
    line-height: 1.2;
  }
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  position: absolute;
  right: 16px;
  svg {
    height: 16px;
    width: 16px;
  }
`;

export default TextInput;
