import { FC, memo } from 'react';

interface Props {
  color: string;
}

const RatingStar: FC<Props> = ({ color = '#FFB800' }) => {
  return (
    <svg
      width="27"
      height="25"
      viewBox="0 0 27 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5 1.61804L16.0554 9.48278L16.1677 9.82827H16.5309H24.8004L18.1103 14.689L17.8164 14.9025L17.9286 15.248L20.484 23.1127L13.7939 18.252L13.5 18.0385L13.2061 18.252L6.51596 23.1127L9.07137 15.248L9.18362 14.9025L8.88973 14.689L2.19958 9.82827H10.4691H10.8323L10.9446 9.48278L13.5 1.61804Z"
        fill={color}
        stroke="#FFB800"
      />
    </svg>
  );
};

export default memo(RatingStar);
