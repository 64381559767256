import { COLORS } from '@landing/constants';
import { FC, ReactNode } from 'react';
import styled from 'styled-components';

type Props = {
  className?: string;
  children: ReactNode;
};

const ContentBox: FC<Props> = ({ children, className }) => {
  return (
    <ContentBoxWrapper className={className}>{children}</ContentBoxWrapper>
  );
};

export default ContentBox;

const ContentBoxWrapper = styled.div`
  background: ${COLORS.white};
  box-shadow: 0 4px 55px 0 rgba(58, 54, 219, 0.05);
  border-radius: 16px;
`;
